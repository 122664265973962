/** @jsx jsx */
import { FunctionComponent } from "react"
import { jsx } from "theme-ui"
import { motion } from "framer-motion"
import styled from "@emotion/styled"

type HoverProps = {
  mx?: string
  children: any
  scale?: number
  whileHover?: any
  whileTap?: any
}

const AnimatedHover: FunctionComponent<HoverProps> = styled(motion.div)`
  display: inline-block;
  margin: 0 ${({ mx }: HoverProps) => mx};
  cursor: pointer;
`

const Hover = (props: HoverProps) => (
  <AnimatedHover
    whileHover={{
      scale: props.scale || 1.15,
      transition: { duration: 0.5, ease: "easeInOut" },
    }}
    whileTap={{ scale: 1, transition: { duration: 0.25 } }}
    mx={props.mx}
  >
    {props.children}
  </AnimatedHover>
)

export default Hover
