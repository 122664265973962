/** @jsx jsx */
import { useState, useEffect, useRef } from "react"
import { jsx, Box, Link, Flex, Text, Button } from "theme-ui"

import { motion } from "framer-motion"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hover from "../components/hover"
import Footer from "../components/footer"

import Logo from "../assets/smartnode-logo.svg"

import githubIcon from "../images/github.svg"
import facebookIcon from "../images/facebook.svg"
import twitterIcon from "../images/twitter.svg"
import linkedInIcon from "../images/linkedin.svg"
import discordIcon from "../images/discord.svg"

import "../styles/canvas.css"
import createGlobe from "cobe"

import { InlineWidget, CalendlyEventListener } from "react-calendly"

import ReactGA from "react-ga"
ReactGA.initialize("UA-87597805-1")
ReactGA.pageview("home")

const variants = {
  open: { opacity: 1, x: 0, height: "100%" },
  closed: { opacity: 0, x: "-100%", height: 0 },
}

const HomePage = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const ref = useRef()
  let phi = 0

  const _updateAppointment = (data: any) => {
    const uri = data.payload.event.uri
    console.log(">", data, uri)
  }

  useEffect(() => {
    if (typeof document !== `undefined`) {
      let canvas = document.getElementById("cobe") as HTMLCanvasElement
      let globe = createGlobe(canvas, {
        devicePixelRatio: 2,
        width: 700,
        height: 700,
        phi,
        theta: 0.25,
        diffuse: 1.2,
        dark: 0.2,
        mapSamples: 5000,
        mapBrightness: 1,
        baseColor: [1, 1, 1],
        markerColor: [0, 0.7, 0.8],
        glowColor: [0.1, 0.1, 0.1],
        markers: [{ location: [40.7128, -74.006], size: 0.1 }],
        onRender: state => {
          state.phi = phi
          phi += 0.01
        },
      })
      return () => {
        globe.destroy()
      }
    }
  }, [])

  return (
    <Layout>
      <SEO
        title="Hello"
        description="SmartNode is a digital product company based in New York City."
        meta="SmartNode Product Design Development Engineering NYC"
      />
      <div id="bg" />
      <canvas
        id="cobe"
        style={{ width: "500px", height: "500px" }}
        width="750"
        height="750"
      ></canvas>

      <Box>
        <Logo />
        <Flex sx={{ flexDirection: "column", alignItems: "center" }}>
          <Text
            sx={{
              textAlign: "center",
              fontSize: "md",
              fontWeight: 300,
              color: "black",
            }}
          >
            A Digital Product Company
            <br />
            in New York City.
          </Text>
          <Box my={3}>
            <Hover>
              <Link href="mailto:info@smartnode.codes">
                <Button p="3">Get in Touch</Button>
              </Link>
            </Hover>

            <Hover>
              <Button p="3" ml="3" onClick={() => setIsOpen(isOpen => !isOpen)}>
                Schedule a Chat
              </Button>
            </Hover>
          </Box>

          <Flex mt={3} className="social">
            <Hover>
              <Link href="https://github.com/smartnodecodes" mx={2}>
                <img src={githubIcon} width="32" alt="GitHub" />
              </Link>
            </Hover>
            <Hover>
              <Link href="https://facebook.com/smartnodecodes" mx={2}>
                <img src={facebookIcon} width="32" alt="Facebook" />
              </Link>
            </Hover>
            <Hover>
              <Link href="https://twitter.com/smartnodecodes" mx={2}>
                <img src={twitterIcon} width="32" alt="Twitter" />
              </Link>
            </Hover>
            <Hover>
              <Link
                href="https://www.linkedin.com/company/smartnodecodes/"
                mx={2}
              >
                <img src={linkedInIcon} width="32" alt="LinkedIn" />
              </Link>
            </Hover>

            <Hover>
              <Link href="https://discord.gg/qtXUrQeqZ5" mx={2}>
                <img src={discordIcon} width="32" alt="Discord" />
              </Link>
            </Hover>
          </Flex>
        </Flex>
      </Box>
      <motion.div animate={isOpen ? "open" : "closed"} variants={variants}>
        <CalendlyEventListener
          onEventScheduled={resp => _updateAppointment(resp.data)}
        >
          <InlineWidget
            url="https://calendly.com/smart-node"
            prefill={{
              customAnswers: {
                a1: "Hello SmartNode! ",
              },
            }}
          />
        </CalendlyEventListener>
      </motion.div>

      <Footer />
    </Layout>
  )
}

export default HomePage
