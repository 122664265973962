/** @jsx jsx */
import React from "react"
import { jsx, Flex, Text } from "theme-ui"

const Footer: React.FunctionComponent = () => {
  return (
    <footer
      sx={{
        position: "fixed",
        width: "100%",
        bottom: 0,
        right: 0,
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        p: 3,
      }}
    >
      <Flex sx={{ p: 2 }}>
        <Text sx={{ fontSize: 12, fontWeight: 300 }}>
          {new Date().getFullYear()} &ndash; SmartNode, LLC
        </Text>
      </Flex>
    </footer>
  )
}

export default Footer
